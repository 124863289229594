#header-logo {
    width: 350px;
    max-width: 60vw;
    margin: 1.5rem 1rem;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100vw;
}

#burger {
    width: 30px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s;
    position: relative;
    margin: 1rem;

}

.open-menu{
    top: 1vh !important;

}

.burger-line {
    height: 6px;
    width: 100%;
    background-color: white;
    transition: all 0.3s;
}

#burger.open-menu .burger-line:nth-child(1) {
    transform: translateY(-5px) rotate(90deg) scaleX(0.5);
}

#burger.open-menu .burger-line:nth-child(2) {
    transform: translateY(-5px);
}

#burger.open-menu .burger-line:nth-child(3) {
    transform: translateY(-25px) rotate(90deg) scaleX(0.5);
}

#burger.open-menu .burger-line:nth-child(4) {
    position: absolute;
    transform: translate(-8px, -3px) rotate(45deg) scaleX(0.7);
    width: 70%;
    display: block;

}

#burger.open-menu .burger-line:nth-child(5) {
    position: absolute;
    transform: translate(8px, -3px) rotate(-45deg) scaleX(0.7);
    width: 70%;
    display: block;
}

#burger .burger-line:nth-child(4), #burger .burger-line:nth-child(5){
    display: none;
}

#basket-icon{
    width: 30px;
    height: 30px;
    object-fit: contain;
    object-position: center;
    position: relative;
    top: -3px;
    margin-right: 5px;
    filter: brightness(0) invert(1);
}

#mobile-basket-icon{
    display: none;
}

  #basket-icon:hover{
    cursor: pointer;
  }

@media only screen and (max-width: 600px) {
    #burger {
        width: 30px;
        height: 25px;
    }

    .burger-line {
        height: 6px;
       
    }

    #mobile-basket-icon{
        display: block;
        width: 30px;
        height: 30px;
        object-fit: contain;
        object-position: center;
        position: relative;
        top: -5px;
        margin-left: 15px;
        filter: brightness(0) invert(1);
    }

    #basket-icon{
        display: none;
    }
  }
.nav-open{
    height: 100vh; /* 100% Full-height */
    width: 100vw; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 998; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #FB4622;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .nav-closed{
    height: 100vh; /* 100% Full-height */
      width: 0; /* 0 width - change this with JavaScript */
      position: fixed; /* Stay in place */
      z-index: 998; /* Stay on top */
      top: 0; /* Stay at the top */
      left: 0;
      background-color: #FB4622;
      overflow-x: hidden; /* Disable horizontal scroll */
      padding-top: 60px; /* Place content 60px from the top */
      transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  }
  
  .nav-closed > *{
    display: none;
  }
  
  .nav-open a{
      text-decoration: none;
      font-size: 20px;
      color: #fff;
      display: block;
      transition: 0.3s;
      font-size: 2rem;
      text-transform: uppercase;
      font-family: "BigSans", serif;
      text-align: center;
      margin: 1rem;
  } 
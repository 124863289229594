#trade{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 4rem 2rem;
    background-image: url('../../Assets/bar.webp');
    min-height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center top;
    color: white;
    font-family: 'Futura-pt', sans-serif;
    position: relative;
}

#trade-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(17,17,17, 0.4);
    z-index: 2;


}

#trade h1{
    font-family: 'BigSans', sans-serif;
    font-weight: 500;
}

#trade-container-left{
    width: 50vw;
    max-width: 700px;
    margin: 1rem auto;
    padding: 2rem;
    z-index: 3;
    font-size: 22px;
    
}

#trade-container-left h3{
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 24px;
    font-weight: 500;
}

#trade-form{
    width: 50vw;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    max-width: 600px;
    margin: 1rem auto;
    text-align: center;
    z-index: 3;
    font-size: 18px;

}

.trade-signup-input{
    margin: 10px 0;
    height: 48px;
    font-family: 'Futura-pt', sans-serif;
    font-size: 16px;
    padding-left: 10px;
}

#trade-signup-txt-area{
    margin: 10px 0;
    height: 82px;
    font-family: 'Futura-pt', sans-serif;
    font-size: 16px;
    padding: 10px;
    resize: none;
}

#submit-trade-btn{
    border: 2px solid white;
    color: white;
    background-color: rgba(17,17,17, 0.6);
    font-family: 'Futura-pt', sans-serif;
    width: 150px;
    margin: 1rem auto;
    padding: 10px 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

#want-to-stock{
    font-family: 'DesMontille', sans-serif;
    font-size: 100px;
    margin:0
}

#premium-tequila{
    font-family: 'BigSans', sans-serif;
    font-size: 96px;
    color: #77FD78;
    margin:0

}

@media only screen and (max-width: 1200px) {

    #trade-container-left, #trade-form{
        width: 100%;
        padding: 1rem;
    }
    #trade h1{
        font-size: 6vw;
    }
  }

  @media only screen and (max-width: 750px) {
    #trade{
        padding: 4rem 1rem;
    }
    #want-to-stock{
        font-size: 14vw;
    }
    
    #premium-tequila{
        font-size: 12vw;
       
    }
    #trade-container-left{
        font-size: 18px;
        
    }
  }
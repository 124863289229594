/* The side navigation menu */
.sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1001; /* Stay on top */
    top: 0; /* Stay at the top */
    right: 0;
    background-color: #fff; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  }

  .sidenav > *, .sidenav-open > *{
    color: #313131;
  }

  .sidenav > * {
    visibility: hidden;
  }
  
.sidenav-open {
    height: 100%; /* 100% Full-height */
    width: 400px; /* 0 width - change this with JavaScript */
    max-width: 100vw;
    position: fixed; /* Stay in place */
    z-index: 999; /* Stay on top */
    top: 0; /* Stay at the top */
    right: 0;
    background-color: #fff; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    padding:  1rem;

  }

  /* The navigation menu links */
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  /* Position and style the close button (top right corner) */
  
  .close-button {
    position: relative;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .close-button::before,
  .close-button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 4px;
    background-color: #FB4622;
    transform-origin: center;
  }
  
  .close-button::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  
  .close-button::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .subscribe-btn{
    border: 2px solid #1e1e1e;
    color: #1e1e1e;
    background-color: white;
    margin: 0 auto 1rem auto;
    font-family: GreenGroveBold;
    text-transform: uppercase;
    height: 40px;
    width: 100px;
  }
  
  /* Style page content - use this if you want to push the page content to the right when you open the side navigation */
  #main {
    transition: margin-left .5s;
    padding: 20px;
  }

  #basket-input{
    width: 100%;
    border: 1px solid white;
    font-family: Gotham;
    font-size: 16px;
    color: white;
  }

  #mySidenav{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  #basket-form{
    width: 100%;
    margin: 0 auto;
  }

  #checkout-btn{
    background-color: #FB4622;
    color: white;
    text-decoration: none;
    font-family: 'BigSans', sans-serif;
    width: calc(100% - 2rem);
    text-align: center;
    margin: 1rem 1rem 0 1rem;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #continue-shopping{
    background-color: #fff;
    border: 2px solid #FB4622;
    color: #FB4622;
    text-decoration: none;
    font-family: 'Futura-pt', sans-serif;
    width: calc(100% - 2rem);
    text-align: center;
    margin: 1rem;
    font-size: 18px;
    height: 50px;
  }

  #btn-container{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .basket-qty{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FB4622;
    font-family: 'Futura-pt', sans-serif;
    font-weight: 500;
  }

  .basket-item-total{
    text-align: right;
    padding-left: 10px;
    color: #FB4622;
    font-family: 'Futura-pt', sans-serif;
    font-weight: 600;
    font-size: 16px;
  }

  .basket-grand-total{
    color: #FB4622;
    font-family: 'Futura-pt', sans-serif;
    font-weight: 700;
    font-size: 32px;
    margin: 0px;
  }

  .change-qty-btn{
    background-color: unset;
    border: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    color: #FB4622;
    margin: 0 5px;
    font-family: 'Futura-pt', sans-serif;
    font-weight: 700;
    font-size: 24px;
  }
 
  #cart-title{
    font-family: 'BigSans', sans-serif;
    color: #FB4622;
    font-size: 22px;
  }

  .cart-img{
    width: 15%;
    margin-right: 20px
  }

  .cart-line{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
  }

  .cart-item-name{
    color: #FB4622;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0;
  }
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }
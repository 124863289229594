#allure{
    background-color: #fff;
    color:'#fb4622';
    width: 100vw;
    padding: 4rem 0;
    display: flex;
    align-items: center;
}

#barrels-story{
    height: 700px;
    object-fit: cover;
    object-position: center;
    width: 50vw;
    max-width: 700px;
    border-radius: 0 2px 2px 0;
    margin: auto 0;
}

#barrels-story-mobile{
    display: none;
}

#the-allure{
    font-family: 'BigSans';
    color: #F472F4;
    font-size: 56px;
    margin: 0;
}

#of-reposado{
    color: #FB4622;
    font-family: 'DesMontille';
    font-size: 128px;
    position: relative;
    top: -60px;
    left: 50px;
    margin: 0;
    height: 100px;

}

.allure-txt{
    color: #FB4622;
    margin: 10px;
    font-family: "futura-pt", sans-serif;
    font-size: 24px;
}

#allure-container{
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: baseline;
    padding: 3rem;
    width: 50vw;
}

@media only screen and (max-width: 1000px) {
    #the-allure{
        font-size: 10vw;
        top: -40px;
    }
    #of-reposado{
        font-size: 8vw;
        top: -40px;
    }
}

@media only screen and (max-width: 750px) {
    #allure{
        flex-direction: column;
        text-align: center;
    }

    #barrels-story{
        display: none;
    }

    #barrels-story-mobile{
        display: block;
        width: 90vw;
        height: 100vw;
        
    }

    #allure-container{
        width: 100vw;
        padding: 1rem;
        align-items: center;

    }

    #the-allure{
        font-size: 12vw;
    }
    
    #of-reposado{
        font-size: 20vw;
        top: -40px;
        left: 0;
    }
  }
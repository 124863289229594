#timeline{
    padding: 4rem 0;
    position: relative;
    color: white;
    font-family: 'Futura-pt', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;

}

#discovering{
    color: #B0F6FA;
    font-family: 'BigSans', sans-serif;
    font-size: 64px;
    margin: 0;
}

#our-spirit{
    color: #77FD78;
    font-family: 'DesMontille', sans-serif;
    font-size: 140px;
    margin: 0;
    position: relative;
    top: -80px;
    left: 200px;

}

#timeline-left{
    width: 50vw;
    position: relative;

}

#timeline-left img{
    position: relative;
}

#timeline-right{
    width: 50vw;
    border-left: 3px solid #77FD78;
    position: relative;

}

.left-txt, .right-txt{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.left-txt p, .right-txt p{
    width: 500px;
    font-size: 18px;
}

.left-txt p{
    text-align: right;
}

.dot-left{
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: #77FD78;
    position: relative;
    right: -12px;
    flex-shrink: 0;
}

.dot-right{
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: #77FD78;
    position: relative;
    left: -12px;
    flex-shrink: 0;

}

.red-line{
    position: absolute;
    left: -4px;
    top: -10px;
    width: 5px;
    height: 50%;
    background-color: #FB4622;
    z-index: 1000;
}

.red-line-right{
    position: absolute;
    right: -4px;
    bottom: -10px;
    width: 5px;
    height: 50%;
    background-color: #FB4622;
    z-index: 1000;
}

.red-line-bottom{
    position: absolute;
    left: -4px;
    bottom: -10px;
    width: 5px;
    height: 350px;
    background-color: #FB4622;
    z-index: 1000;
}

#signature{
    position: absolute;
    bottom: 50px;
    right: 0;
    width: 400px;
    z-index: 1001;
}

.timeline-img{
    box-shadow: 4px 4px 10px 4px #0000001A;
}

#timeline-desktop{
    display: flex;
    width: 1200px;
    max-width: 100%;
}

#timeline-mobile{
    display: none;
}

  
 
 


@media only screen and (max-width: 850px) {
    #discovering{
        font-size: 9vw;
    }
    
    #our-spirit{
        font-size: 15vw;
        top: -8vw;
        left: 0px;
    }

    #timeline-desktop{
        display: none;
    }

    #timeline-mobile{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    #timeline-mobile p{
        padding: 1rem;
    }

  .timeline-img-mobile {
    
    box-shadow: 4px 4px 10px 4px #0000001A;

  }

  #signature-mobile{
    width: 400px;
    max-width: 80vw;
    margin: 1rem auto;
    text-align: center;
  }
  
  }
*{
  box-sizing: border-box;
}

html{
  padding: 0;
  margin: 0;
  background-color: #FB4622;
}

a{
  text-decoration: none;
}

.thiago-btn{
  background-color: #77FD78;
  border: unset;
  color: #FB4622;
  padding: 10px 20px 5px 20px;
  font-family: 'GreenGroveBold';
  font-size: 24px;
  text-transform: uppercase;
  margin: 2rem 0;
  text-decoration: none;
}

.thiago-btn-purple{
  background-color: #F472F4;
  border: unset;
  color: #fff;
  padding: 10px 20px 5px 20px;
  font-family: 'GreenGroveBold';
  font-size: 24px;
  text-transform: uppercase;
  margin: 2rem 0;
  text-decoration: none;
}

button:hover, a:hover, .hover:hover{
  cursor: pointer;
}



@font-face {
  font-family: GreenGrove;
  src: url(./fonts/GreenGroveLight.otf);
  font-display: fallback;
}

@font-face {
  font-family: GreenGroveBold;
  src: url(./fonts/GreenGroveBold.otf);
  font-display: fallback;
}

@font-face {
  font-family: Gotham;
  src: url(./fonts/Gotham-Light.otf);
  font-display: fallback;
}

@font-face {
  font-family: BigSans;
  src: url(./fonts/BigSexySans.otf);
  font-display: fallback;
}

@font-face {
  font-family: DesMontille;
  src: url(./fonts/DesMontille.otf);
  font-display: fallback;
}

@font-face {
  font-family: Signature;
  src: url(./fonts/WiliamSignature.ttf);
  font-display: fallback;
}


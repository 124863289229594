footer{
    background-color: #FB4622;
    min-height: 150px;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 2rem 2rem 0 2rem;
}

.social-icon{
    height: 45px;
    width: 45px;
    margin-right: 10px;
}

#footer-logo{
    width: 130px;
    margin-right: 20px;
}

#footer-btn{
    background-color: #B0F6FA;
    border: unset;
    color: #FB4622;
    text-transform: uppercase;
    font-family: 'GreenGroveBold', serif;
    height: 56px;
    width: 143px;
    font-size: 28px;
    padding-top: 5px;
}

#footer-input{
    border: 3px solid #B0F6FA;
    height: 56px;
    background-color: unset;
    width: 25vw;
    min-width: 200px;
    padding: 10px;
    text-transform: uppercase;
    font-family: 'GreenGroveBold', serif;
    color: #B0F6FA;
    font-size: 28px;
    margin-right: 10px;
    padding-top: 15px;

}

#footer-input::placeholder{
    text-transform: uppercase;
    font-family: 'GreenGroveBold', serif;
    color: #B0F6FA;
    font-size: 28px;
    padding-top: 15px;
}

#footer-container{
    display: flex;
    align-items: end;
}

#join-the-party{
    font-family: 'BigSans', sans-serif;
    color: #B0F6FA;
    margin: 0;
    font-size: 28px;
}

#footer-form{
    display: flex;
    align-items: end ;
}

.footer-link{
    color: #77FD78;
    font-family: "Futura-pt", sans-serif;
    margin: 10px 0;
}

#footer-links{
    display: flex;
    flex-direction: column;
}

#socials-container{
    text-align: right;
    margin: 1rem ;
}

@media only screen and (max-width: 750px) {
    footer, #footer-container {
        flex-direction: column;
        align-items: center;
    }

    #socials-container{
        text-align: center;
        margin: 1rem ;
    }

    #footer-logo{
        margin-right: 0;
        margin-bottom: 1rem;
        width: 40vw;
    }

    #join-the-party{
        margin: 1rem auto;
        text-align: center;
    }

    #footer-form{
        flex-wrap: wrap;
        justify-content: center;
    }

    #footer-input{
        width: 100%;
        margin-bottom: 1rem;
        margin-right: 0;

    }
    #footer-btn{
        width: 50%;
        margin-bottom: 1rem;
    }
}
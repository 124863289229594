.modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(17,17,17,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

}

.age-modal-container{
    position: relative;
    max-width: 90vw;
    width: 1200px;
    height: 80vh;
    border-radius: 12px;
    border: 1px solid #313131;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    background-image: url('../../../Assets/ageVerify.png');
    background-position: center;
    background-size: cover;
}

.discount-modal-container{
    position: relative;
    max-width: 90vw;
    width: 1200px;
    height: 80vh;
    border-radius: 12px;
    border: 1px solid #313131;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    background-image: url('../../../Assets/15-off.jpg');
    background-position: right;
    background-size: cover;

}

.age-btn{
    background-color: #FF3F00;
    color: #77FD78;
    font-family: 'BigSans', sans-serif;
    border-radius: 15px;
    font-size: 64px;
    border: unset;
    padding: 20px 30px;
    margin: 20px;
}

#are-you{
    text-align: center;
    width: 600px;
    max-width: 90%;
    font-family: 'BigSans', sans-serif;
    font-size: 48px;
    color: #FF3F00;
    margin: 1rem auto;
}

.black-btn{
    background-color: black;
    color: white;
    height: 48px;
    border: unset;
    font-family: 'GreenGroveBold', serif;
    text-transform: uppercase;
    width: 150px;
    font-size: 20px;
}

#modal-input{
    border: 3px solid black;
    height: 56px;
    background-color: unset;
    width: 25vw;
    min-width: 200px;
    padding: 10px;
    text-transform: uppercase;
    font-family: 'GreenGroveBold', serif;
    color: black;
    font-size: 28px;
    margin-right: 10px;
    padding-top: 15px;

}

#modal-input::placeholder{
    text-transform: uppercase;
    font-family: 'GreenGroveBold', serif;
    color: black;
    font-size: 28px;
    padding-top: 15px;
}

@media only screen and (max-width: 600px) {
    .age-modal-container{
        padding: 1rem;
    }

    #are-you{
        font-size: 36px;
        margin: 0;
        width: 100%;
        max-width: 100%;
    }

    #you-must{
        font-size: 18px;

    }

    .age-btn{
        font-size: 48px;
    }

    #modal-input{
        width: 100%;
        margin-bottom: 1rem;
        margin-right: 0;

    }

  }
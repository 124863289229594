#hero{
    background-image: url('../../../Assets/hero.webp');
    width: 100vw;
    min-height: 800px;
    height: 100vh;
    background-size: cover;
    background-position: top;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
}

#hero-txt{
    color: #FB4622;
    font-family: 'BigSans';
    text-transform: uppercase;
    font-size: 84px;
    text-align: left;
    width: 900px;
    max-width: 80vw;
    margin: 0;
}

@media only screen and (max-width: 750px) {
    #hero {
        align-items: center;
        padding: 0;
        background-image: url('../../../Assets/hero-mobile.webp');

    }
    #hero-txt{
        text-align: center;
        font-size: 10vw;
        max-width: 95vw;
       
    }
  }

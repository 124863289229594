#bar-tab{
    position: fixed;
    right: 10px;
    bottom: 0;
    width: 150px;
    height: 50px;
    background-color: #77FD78;
    color: #fb4622;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Futura-pt', sans-serif;
    font-size: 18px;
    z-index: 997;
    font-weight: bold;
}

#bar-tab p{
    margin: 0;
}

#bar-tab:hover{
    height: 65px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

@media only screen and (max-width: 750px) {
    #bar-tab:hover{
        height: 40px;
    }

    #bar-tab{
        
        font-size: 14px;
        width: 120px;
        height: 40px;
    }
}
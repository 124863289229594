#flavour-revolution{
    background-color: #FB4622;
    width: 100vw;
    padding: 4rem 0;
    display: flex;
    align-items: center;
}

#revolution-agave{
    height: 500px;
    object-fit: cover;
    object-position: center;
    width: 50vw;
    max-width: 700px;
    border-radius: 0 2px 2px 0;
    margin: auto 0;
}

#revolution-agave-mobile{
    display: none;
}

#a-flavour{
    font-family: 'BigSans';
    color: #b0f6fa;
    font-size: 56px;
    margin: 0;
}

#revolution{
    color: #77FD78;
    font-family: 'DesMontille';
    font-size: 128px;
    position: relative;
    top: -60px;
    left: 10px;
    margin: 0;
    height: 100px;

}

.revolution-txt{
    color: white;
    margin: 10px;
    font-family: "futura-pt", sans-serif;
    font-size: 20px;
}

#revolution-container{
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: baseline;
    padding: 3rem;
    width: 50vw;
}

@media only screen and (max-width: 1000px) {
    #revolution{
        font-size: 10vw;
        top: -40px;
    }
}

@media only screen and (max-width: 750px) {
    #flavour-revolution{
        flex-direction: column;
        text-align: center;
    }

    #revolution-agave{
        display: none;
    }

    #revolution-agave-mobile{
        display: block;
        width: 90vw;
        height: 100vw;
        
    }

    #revolution-container{
        width: 100vw;
        padding: 1rem;
        align-items: center;

    }

    #a-flavour{
        font-size: 12vw;
    }
    
    #revolution{
        font-size: 20vw;
        top: -40px;
    }
  }
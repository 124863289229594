#explore-flavours{
    display: flex;
    position: relative;
}

#explore-header{
    position: absolute;
    left: 35%;
    top: 7%;
    z-index: 999;
    width: 30%;
    text-align: center;
}

#explore-txt{
   color:  #DAFFE0;
   font-family: 'BigSans', sans-serif;
   font-size: 64px;
   margin: 0;

}

#our-flavours{
    position: relative;
    color: white;
    font-size: 96px;
    font-family: 'DesMontille', sans-serif;
    margin: 0;
    top: -50px;
}

.explore-flavour-container{
    background-size: cover;
    background-position: center;
    height: 35vw;
    width: 25vw;
    color: white;
    font-family: 'BigSans', sans-serif;
    text-align: center;
    font-size: 2vw;
    position: relative;
    transition: 0.3s ease-in-out;

}

.explore-flavour-container-shrunk{
    background-size: cover;
    background-position: center;
    height: 35vw;
    width: 0px;
    color: white;
    font-family: 'BigSans', sans-serif;
    text-align: center;
    font-size: 2vw;
    position: relative;
    transition: 0.3s ease-in-out;
    overflow: hidden;
}

.explore-flavour-container-expanded{
    background-size: cover;
    background-position: center;
    height: 35vw;
    width: 100vw;
    color: white;
    font-family: 'BigSans', sans-serif;
    text-align: center;
    font-size: 2vw;
    left: 0;
    top: 0;
    z-index: 1000;
    transition: 0.3s ease-in-out;
}

.explore-flavour-title{
    position: absolute;
    top: 75%;
    width: 100%;
    padding: 0 70px;
}

.flavour-overview{
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

.flavour-overview-hidden{
    width: 0;
    height: 100%;
    transition: 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

.flavour-overview-img{
    height: 100%;
    width: 40%;
    object-fit: cover;
    object-position: center;

}

.flavour-overview-info{
    background-color: white;
    height: 100%;
    width: 60%;
    text-align: left;
    padding: 3rem;
}

.flavour-overview-txt{
    font-family: 'Futura-pt', sans-serif;
    font-size: 20px;
}

@media only screen and (max-width: 950px) {

    #explore-flavours{
        flex-wrap: wrap;

    }

    .explore-flavour-container{
        width: 50vw;
        height: 70vw;
        font-size: 5vw;
        text-align: center;
        display: flex;
        justify-content:center;
        align-items: end;

    }
    .explore-flavour-title{
        position: relative;
        top: unset;
        width: 90%;
        padding: 0;
    }

    #explore-txt{
        font-size: 12vw;     
     }
   
     #explore-header{
        position: absolute;
        left: 10%;
        top: 20px;
        z-index: 999;
        width: 80%;
        text-align: center;
    }

    #our-flavours{
        position: relative;
        color: white;
        font-size: 14vw;
        font-family: 'DesMontille', sans-serif;
        margin: 0;
        top: -6vw;
    }

    .explore-flavour-container-expanded{
        background-size: cover;
        background-position: center;
        height: 140vw;
        width: 100vw;
        color: white;
        font-family: 'BigSans', sans-serif;
        text-align: center;
        font-size: 6vw;
        left: 0;
        top: 0;
        z-index: 1000;
        transition: 0.3s ease-in-out;
    }
    .flavour-overview-info{
        background-color: white;
        height: 100%;
        width: 60%;
        text-align: left;
        padding: 1rem;
    }
    
    .flavour-overview-txt{
        font-family: 'Futura-pt', sans-serif;
        font-size: 4vw;
    }

    .flavour-overview-header{
        margin: 0;
    }
  }
#our-story-hero{
    background-image: url('../../../Assets/our-story.webp');
    height: 80vh;
    min-height: 700px;
    background-position: center;
    background-size: cover;
    padding: 3rem 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
}


#story-hero-header{
    font-family: 'BigSans', sans-serif;
    color: #77FD78;
    text-transform: uppercase;
    font-size: 84px;
    text-align: left;
    margin: 0;
}

#our-story-mexico{
    color: #B0F6FA;
    font-family: 'DesMontille';
    font-size: 140px;
    position: relative;
    top: -90px;
    left: 170px;
    margin: 0;
    height: 100px;

}

#our-story-desc{
    width: 600px;
    max-width: 90vw;
    color: white;
    font-family: 'Futura-pt', sans-serif;
    font-size: 24px;
    line-height: 31px;
}

#our-story-highlights{
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#story-highlight-txt{
    color: white;
    font-family: 'Futura-pt', sans-serif;
    font-size: 24px;
    text-align: center;
    width: 1200px;
    max-width: 100%;
}

.story-highlight-container{
    padding: 2rem;
    text-align: center;
    text-transform: uppercase;
    width: 200px;
    color:#77FD78;
    font-family: 'Futura-pt', sans-serif;
    letter-spacing: 2px;
    
}

.story-highlight-icon{
    width: 60px;
    height: 60px;
    object-fit: contain;
}

#highlights{
    display: flex;
    justify-content: space-evenly;
    width: 1200px;
    max-width: 100%;
    flex-wrap: wrap;

}

@media only screen and (max-width: 750px) {
    #story-hero-header{
        font-size: 15vw;
        text-align: center;
    }

    #our-story-hero{
        align-items: center;
        justify-content:left;
        background-position: bottom;
        padding: 0;
        padding-top: 200px;

    }

    #our-story-mexico{
        font-size: 25vw;
        top: -15vw;
        left: 0;
    }

    #our-story-btn{
        margin: 0;
    }

    #our-story-desc{
        display: none;
    }
    .story-highlight-container{
        padding: 1rem;
       width: 50%;
        
    }
  }